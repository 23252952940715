<template>
    <div class="partner_selector">
        <el-select v-model="selectedId" @change="setPartnerId" size="mini" placeholder="파트너 선택">
            <el-option
                    :key="0"
                    label="전체"
                    :value="null">
            </el-option>
            <el-option
                    v-for="item in agentList"
                    :key="item.id"
                    :label="item.nickname"
                    :value="item.id">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import {manager} from "../../common/administrator/managerMixin";
    import {getPartnerList} from "../../network/manager/partnerRequest";
    import {getAgentList} from "../../network/manager/agentRequest";
    import managerConst from "../../common/administrator/managerConst";
    import {getSubAgentList} from "../../network/agent/commonRequest";
    import {agentMixin} from "../../common/agent/agentMixin";

    export default {
        name: "PartnerSubSelectorComp",
        mixins: [agentMixin],
        data() {
            return {
                selectedId:null,
                agentList: [],
                agent: {
                    'parentId': managerConst.Parnter.PATNER_GENERALAGENT_NOID
                },
                pageNum: 1,
                pageSize: 1000,
            }
        },
        methods: {
            setPartnerId(){
                this.$emit('setPartnerId',this.selectedId)
            },
            makeAgentList(list) {
                for (let i = 0; i < list.length; i++) {
                    let agent = list[i];
                    agent.nickname = '('+agent.id+ ')' + agent.nickname;
                    this.agentList.push(agent);
                    if(agent.subAgentList && agent.subAgentList.length > 0){
                        this.makeAgentList(agent.subAgentList)
                    }
                }
            }
        },
        computed: {},
        created() {
            getSubAgentList({'parent_id': 0}, 1, 10000).then(res => {
                let list = res.data.data;
                this.makeAgentList(list);
            })
        },
        watch: {},
    }
</script>

<style scoped>

</style>